<template>
  <v-container fluid class="pa-10 mt-10 mb-10" :style="`background-color:${contact.bgColor}`">
      <v-card
        id="contact"
        flat
        width="100%"
        class="pa-2 text-left"
        :style="`background-color:${contact.bgColor}`"
      >
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-img
                :src="contact.img"
                width="100%"
                height="100%"
                :contain="false"
              >
              </v-img>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <h4 class="display-1 text-uppercase">{{contact.title}}</h4>
              <p class="mt-3 text--secondary">{{contact.subTitle}}</p>
              <p class="mt-10"><v-icon class="mr-4" v-if="contact.email">mdi-email</v-icon>{{contact.email}}</p>
              <p class="mt-10"><v-icon class="mr-4" v-if="contact.tel">mdi-phone</v-icon>{{contact.tel}}</p>
              <p class="mt-10"><v-icon class="mr-4" v-if="contact.whatsapp">mdi-whatsapp</v-icon>{{contact.whatsapp}}</p>
              <div id="hubspot"></div>
            </v-col>
          </v-row>
      </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'Contact',
    computed: {
      contact () {
        return this.$store.state.contact;
      }
    },
    mounted () {   
      window.hbspt.forms.create({
                region: "na1",
                portalId: "7332789",
                formId: "6f37c85f-ef42-4d76-ae2c-88f4363f70d6",
                version: "V2_PRERELEASE",
                target: "#hubspot"
              });
    },
    data: () => ({
    })
  }
</script>