<template>
  <div>
    <v-navigation-drawer
        app
        v-model="menu"
        temporary
        class="text-center white--text"
      >
        <template>
          <v-card
            width="300"
            tile
          >
             <v-img
                contain
                class="mt-n4"
                :src="config.logo"
                height="100px"
              ></v-img>
            <v-list
              dense
              v-for="(item, i) in config.links"
              :key="i">

              <v-list-item
                v-if="!item.subItems.length"
                link
                @click="goTo(item)"
              >
                <v-list-item-icon>
                  <v-icon></v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-left">{{item.texto}}</v-list-item-title>
              </v-list-item>

              <v-list-group
                  v-else
                  no-action
                >
                    <template v-slot:activator>
                      <v-list-item-icon>
                        <v-icon></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="text-left">{{item.texto}}</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item
                      v-for="(subItem, i) in item.subItems"
                      :key="i"
                      :href="subItem.href"
                      link
                    >
                      <v-list-item-title
                        class="text-left"
                        v-text="subItem.texto"></v-list-item-title>
                        <v-list-item-icon>
                          <v-icon></v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>
            <v-divider></v-divider>
            <v-list>
              <v-list-item dense>

                <v-list-item-content>
                  <v-list-item-title>{{contact.tel}}</v-list-item-title>
                  <v-list-item-subtitle>Tel</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider inset></v-divider>
              <v-list-item dense>

                <v-list-item-content>
                  <v-list-item-title>{{contact.whatsapp}}</v-list-item-title>
                  <v-list-item-subtitle>Whastapp</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
             <v-divider inset></v-divider>

              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>{{contact.email}}</v-list-item-title>
                  <v-list-item-subtitle>Email Official</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

            </v-list>
          </v-card>
        </template>
    </v-navigation-drawer>
    <v-app-bar
      app
      flat
      elevate-on-scroll
      hide-on-scroll
      :color="config.color"
      >
      <!-- -->
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click.stop="menu = !menu"
      ></v-app-bar-nav-icon>

        <a :href="''">
          <v-img
          :width="$vuetify.breakpoint.smAndDown ? '200px' : '200px'"
          :src="config.logo"
          ></v-img>
        </a>
       <v-spacer></v-spacer>
      <div v-if="!$vuetify.breakpoint.smAndDown">
        <v-menu
          v-for="(item, i) in config.links"
          :key="i"
          transition="slide-y-transition"
          bottom
          offset-y
          open-on-hover
        >
          <template v-slot:activator="{ on }">
            <v-btn
              text
              small
              :color="config.btnColor"
              class="caption"
              v-on="on"
              @click="goTo(item)"
            >
              {{ item.texto }}
            </v-btn>
          </template>
            <v-list
            v-if="item.subItems.length"
            :color="bgColor"
            >
              <v-list-item
              v-for="(subItem, i) in item.subItems"
              :key="i"
              :href="subItem && subItem.href"
              >
                <v-list-item-content>
                  <v-list-item-title
                  v-text="subItem.texto"
                  class="lighten-1 white--text caption"
                  :color="color"
                  >
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
        </v-menu>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
  export default {
    name: 'NavBar',
    computed: {
      config () {
        return this.$store.state.navBar;
      },
      contact () {
        return this.$store.state.contact;
      }
    },
    data: () => ({
      menu: false,
      active: false
    }),
    methods: {
      goTo (item) {
        if (item.href.indexOf("http://") == 0 || item.href.indexOf("https://") == 0) {
          window.location = item.href;
        }
        else{
          this.$vuetify.goTo(item.href);
        }
      }
    }
  }
</script>