import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const theme = {
  primary: '#172E54',
  secondary: '#4BC9E8',
  tertiary: '#FFFFFF',
  accent: '#4BC9E8',
  error: '#f44336',
  warning: '#ffeb3b',
  info: '#607d8b',
  success: '#8bc34a',
  textPrimary: '#171717',
  textSecondary: '#707070',
  textTertiary: '#b3b3b3',
};

const publicPath = './';
console.log(publicPath)
export default new Vuex.Store({
  state: {
    theme: {
      dark: false,
      themes: {
        light: theme,
      },
    },
    publicPath: publicPath,
    navBar: {
      logo: `${publicPath}/img/fed-logo.png`,
      title: 'Federación Patronal',
      color: theme.tertiary,
      bgColor: theme.tertiary,
      btnColor: theme.primary,
      links: [
        {
          texto: 'Simulador prestamo',
          href: '#calc',
          subItems: []
        },
        {
          texto: 'Beneficios',
          href: '#benefits',
          subItems: []
        },
        {
          texto: 'Requisitos',
          href: '#requirements',
          subItems: []
        },
        {
          texto: 'Preguntas Frecuentes',
          href: '#faqs',
          subItems: []
        },
        {
          texto: 'Contacto',
          href: '#contact',
          subItems: []
        },
      ]
    },
    header: {
      color: '',
      width: '100%',
      height: '702',
      src: `${publicPath}img/fed-header.jpg`,
      contain: false
    },
    footer: {
      version: 'Versión 01.08.22-16:34',
      color: theme.primary,
      img: `${publicPath}img/fed-logo-white.png`,
      links: [
        {
          texto: 'Simulador prestamo',
          href: '#calc',
          subItems: []
        },
        {
          texto: 'Beneficios',
          href: '#benefits',
          subItems: []
        },
        {
          texto: 'Requisitos',
          href: '#requirements',
          subItems: []
        },
        {
          texto: 'Preguntas Frecuentes',
          href: '#faqs',
          subItems: []
        },
      ]
    },
    benefits: {
      title: "¿qué beneficios me ofrecen?",
      subTitle: `Sacá tu préstamo prendario y
      comprá el auto que más te guste, a quien vos quieras y sin intermediarios`,
      items: [
        {
          title: "Rapidez y Agilidad",
          img: `${publicPath}img/fed-volante.png`,
          text: "para la compra de<br> los vehículos nuevos y usados<br> de 1 año de antiguedad"
        },
        {
          title: "aprobación",
          img: `${publicPath}img/fed-ok.png`,
          text: "entre 24hs y 48hs <br> de haber ingresado el trámite."
        },
        {
          title: "Financiamiento de <br> Hasta el 70%",
          img: `${publicPath}img/fed-money-security.png`,
          text: "de tu compra, hasta 60 cuotas"
        },
        {
          title: "tasa fija y en pesos",
          img: `${publicPath}img/fed-porcentaje.png`,
          text: ""
        },
        {
          title: "Asesoramiento <br> sin costo",
          img: `${publicPath}img/fed-cust-service.png`,
          text: ""
        },
        {
          title: "Con el respaldo <br> del banco ICBC",
          img: `${publicPath}img/fed-bank.png`,
          text: ""
        }
      ]
    },
    requirements: {
      title: "¿quiénes pueden acceder?",
      subTitle: "",
      items: [
        {
          title: "Ser mayores de 18 años",
          img: `${publicPath}img/fed-18.png`,
          text: ""
        },
        {
          title: "trabajador independiente /<br> relación de dependencia",
          img: `${publicPath}img/fed-bag.png`,
          text: ""
        },
        {
          title: "Buen Antecedente Crediticio",
          img: `${publicPath}img/fed-card.png`,
          text: ""
        }
      ]
    },
    faqs: {
      bgColor: "#f4f6f9",
      title: "Preguntas Frecuentes",
      subTitle: "",
      items: [
        {
          title: "¿Qué es un crédito prendario?",
          text: "Un Préstamo Prendario es un plan de financiación para la compra de vehículos para uso particular."
        },
        {
          title: "¿Qué antigüedad puede tener la unidad que quiero financiar?",
          text: "Se pueden financiar todos los vehículos 0Km y usados de hasta 8 años de antigüedad"
        },
        {
          title: "¿Hasta qué monto puedo financiar?",
          text: `
            <p>0 KM  y Usados hasta 1 año el monto a financiar es de hasta 70%</p>
            <p>Usados entre 2 a 5 años el monto a financiar es de hasta 60%</p>
            <p>Usados hasta 6 a 8 años el monto a financiar es de hasta 50%</p>
          `
        },
        {
          title: "¿En cuántas cuotas puedo financiar?",
          text: "Los plazos para financiar son de 12, 24, 36, 48 y 60 cuotas "
        },
        {
          title: "¿Las cuotas son fijas?",
          text: "Si son fijas y en pesos. Sistema de amortización Francés"
        },
        {
          title: "¿Cuáles son los medios de pago de las cuotas?",
          text: "Las cuotas se debitan automáticamente de una caja de ahorro del banco ICBC. No es necesario ser cliente del ICBC, una vez aprobado el crédito se abrirá una cuenta al asegurado. "
        },
        {
          title: "¿Se pueden pre-cancelar las cuotas?",
          text: "Si, puede realizarse a partir de la cuota 6. La comisión por cancelación anticipada es del 5% + IVA sobre el capital adeudado. Una vez cancelada la mitad del préstamo, no se cobra comisión por la cancelación anticipada."
        },
        {
          title: "¿Puedo cambiar el auto una vez aprobado el crédito?",
          text: "En este caso, hay que anular el crédito y cargar uno nuevo."
        },
        {
          title: "¿Puede ser emitido el crédito a nombre de dos personas?",
          text: "Si, el crédito puede incluir un codeudor."
        },
        {
          title: "¿Qué gastos adicionales voy a tener?",
          text: "Los gastos de inscripción de prenda, patentamiento o transferencia, según corresponda, y gastos de honorarios por gestoría."
        },
        {
          title: "¿Qué documentación debo presentar al Banco?",
          text: "La documentación dependerá del análisis crediticio del asegurado."
        },
        {
          title: "¿Cuánto tiempo demora el trámite?",
          text: "Una vez ingresado el trámite, la aprobación demora entre 24 y 48 hs."
        },
        {
          title: "¿Que vehículos se pueden financiar?",
          text: "Cualquier vehículo de uso particular. Se excluyen: transportes de pasajeros, taxis, remis, ambulancias etc)."
        },
        {
          title: "¿Cuales son los límites de financiación?",
          text: "Bancarizados: Hasta $2.150.000 solamente con DNI.<br>No Bancarizados: Hasta $1.450.000 solamente con DNI"
        },
      ]
    },
    contact: {
      title: "Contactanos",
      subTitle: `Ante cualquier duda podes comunicarte con nosotros y
      dentro de las próximas 24hs hábiles un representante comercial se contactará
      para asesorarte`,
      img: `${publicPath}img/fed-happy-family.jpg`,
      tel: '0810-362-0117',
      email: 'fedpatprendarios@simplicar.com',
      whatsapp: '+11 6508 1382'
    },
    fees: {
      percentageUnit: 0.06,
      percentageToFinance: 0.012,
      commercialManagement: 21000,
      percentaceVariant: 0.004
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
