<template>
  <v-container fluid class="pa-10 mt-10" :style="`background-color:${faqs.bgColor}`">
      <v-card
        id="faqs"
        flat
        width="100%"
        class="pa-0 text-center"
        :style="`background-color:${faqs.bgColor}`"
      >
            <h4 class="display-1 text-uppercase">{{faqs.title}}</h4>
            <p class="mt-3 text--secondary">{{faqs.subTitle}}</p>
            <v-row class="mt-10 pa-0" v-if="items.length">
              <v-col
                v-for="(item, i) in items"
                :key="i"
                cols="12"
                md="6"
                sm="12"
              >
              <v-expansion-panels focusable tile>
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    {{item.title}}
                    <template v-slot:actions>
                      <v-icon color="">
                        mdi-plus
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-html="item.text"></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              </v-col>
            </v-row>
      </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'Faqs',
    props: {
      items:{
        type: Array,
        default() {
          return [];
        }
      }
    },
    computed: {
      faqs () {
        return this.$store.state.faqs;
      }
    },
    data: () => ({
    })
  }
</script>